



































import {Component, Vue} from "vue-property-decorator";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {VekaletListResponseProvider} from "@/services/VekaletService";
import NoterDetay from "@/components/vekalet/detay/NoterDetay.vue";
import NotList from "@/components/comps/lists/NotList.vue";
import MuvekkilList from "@/components/vekalet/MuvekkilList.vue";


@Component({
    components: {MuvekkilList, NotList, NoterDetay}
})
export default class VekaletDetay extends Vue {

    item: VekaletEntity | null = null;

    mounted() {

        window.setTimeout(() => {
            let index: number = parseInt(this.$route.params.id) - 1;
            this.item = VekaletListResponseProvider().data[index];
            console.log("Buro Detay, Servisten Gelen DATA", this.item);
        }, 200);


    }

}
