














































import {Component, Prop, Vue} from "vue-property-decorator";

import {NoterIslemEntity} from '../../../entity/NoterIslemEntity';
import NoterIslemForm from "@/components/comps/forms/NoterIslemForm.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";


@Component({components: {FormDialogButton, NoterIslemForm}})
export default class NoterDetay extends ObjectInputMixin {

}
